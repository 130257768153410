<template>
    <div>
        <a-button type="danger" shape="circle" icon="profile" @click="show = true" />
        <a-drawer :title="'【' + detail.name + '】的订单'" placement="right" :visible="show" @close="show = false"
            width="600">
            <a-tabs default-active-key="order">
                <a-tab-pane key="order" tab="订单">
                   <orderList :detail="detail"></orderList>
                </a-tab-pane>
                <a-tab-pane key="invoice" tab="发票" force-render>
                    <invoiceList :detail="detail"></invoiceList>
                </a-tab-pane>
            </a-tabs>
        </a-drawer>
    </div>
</template>
<script>
import orderList from './order.vue';
import invoiceList from './invoice.vue';
export default {
    components: {
        orderList,
        invoiceList
    },
    props: {
        detail: {
            type: Object,
            default: function () {
                return new Object;
            }
        },
    },
    data() {
        return {
            show: false,
        }
    },
}
</script>