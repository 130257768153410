<template>
    <div>
        <a-button @click="add" type="primary">新增订单</a-button>
        <a-table rowKey="order_id" :columns="columns" :pagination="pagination" @change="change" :data-source="list"
            :loading="loading" class="mt10">
            <!-- 支付方式 -->
            <template slot="pay" slot-scope="record,index">
                <a-tag color="red" v-if="record.pay_type == 1">淘宝</a-tag>
                <a-tag color="purple" v-if="record.pay_type == 2">对公</a-tag>
                <a-tag color="green" v-if="record.pay_type == 3">微信</a-tag>
                <a-tag color="blue" v-if="record.pay_type == 4">支付宝</a-tag>
            </template>
            <!-- 类型 -->
            <template slot="type" slot-scope="record,index">
                <a-tag color="cyan" v-if="record.type == 1">标准版</a-tag>
                <a-tag color="purple" v-if="record.type == 2">企业版</a-tag>
                <a-tag color="orange" v-if="record.type == 3">图片版</a-tag>
            </template>
            <!-- 交付状态 -->
            <template slot="deliver" slot-scope="record,index">
                <a-tag color="red" v-if="record.deliver_status == 0">待交付</a-tag>
                <a-tag color="red" v-if="record.deliver_status == 2">待修改</a-tag>
                <a-tag color="purple" v-if="record.deliver_status == 1">待反馈</a-tag>
                <a-tag color="orange" v-if="record.deliver_status == 4">没资料</a-tag>
                <a-tag color="blue" v-if="record.deliver_status == 5">部分制作</a-tag>
                <a-tag color="green" v-if="record.deliver_status == 3">已完成</a-tag>
                <a-tag color="#f50" v-if="record.deliver_status == 7">已退款</a-tag>
            </template>
            <!-- 更多信息 -->
             <template slot="expandedRowRender" slot-scope="record,index">
                <div>
                    <div>下单日期：{{ record.consult }}</div>
                    <div>订单号：{{ record.order_no }}</div>
                    <div>交付时间：{{ record.deliver_time }}</div>
                    <div>备注：{{ record.notes }}</div>
                    <div>设计师：{{ record.designer_id }}</div>
                    <div>账号：{{ record.firm_name }} ID:{{ record.firm_id }}</div>
                </div>
             </template>
            <!-- 操作 -->
            <template slot="action" slot-scope="record,index">
                <a-button type="primary" shape="circle" icon="edit" @click="edit(record)" />
            </template>
        </a-table>
        <a-modal width="540px" :title="title" :visible="show" @cancel="show = false" @ok="save">
            <div class="flex">
                <div style="width: 235px;" class="flex alcenter">
                    <div style="width: 80px;">下单日期</div>
                    <a-date-picker v-model="orderData.consult" valueFormat="YYYY-MM-DD" />
                </div>
                <div style="width: 235px;" class="ml20 flex alcenter">
                    <div style="width: 80px;">交付日期</div>
                    <a-date-picker v-model="orderData.deliver_time" valueFormat="YYYY-MM-DD" />
                </div>
            </div>

            <div class="mt10 flex">
                <div style="width: 235px;" class="flex alcenter">
                    <div style="width: 70px;">支付方式：</div>
                    <a-select v-model="orderData.pay_type" style="width: 165px;">
                        <a-select-option :value="1">淘宝</a-select-option>
                        <a-select-option :value="2">对公</a-select-option>
                        <a-select-option :value="3">微信</a-select-option>
                        <a-select-option :value="4">支付宝</a-select-option>
                    </a-select>
                </div>
                <div style="width: 235px;" class="flex alcenter ml20">
                    <div style="width: 70px;">订单号：</div>
                    <a-input v-model="orderData.order_no" style="width: 165px;" />
                </div>
            </div>
            <div class="mt10 flex">
                <div style="width: 155px;" class="flex alcenter">
                    <div style="width: 70px;">金额：</div>
                    <a-input v-model="orderData.deal" style="width: 85px;" type="number" />
                </div>
                <div style="width: 315px;" class="ml20 flex alcenter">
                    <div style="width: 80px;">快捷金额：</div>
                    <div style="width: 235px;">
                        <a-tag color="purple" @click="orderData.deal = 76">76</a-tag>
                        <a-tag color="blue" @click="orderData.deal = 39">39</a-tag>
                        <a-tag color="cyan" @click="orderData.deal = 18.8">18.8</a-tag>
                        <a-tag color="pink" @click="orderData.deal = 37.2">37.2</a-tag>
                    </div>
                </div>
            </div>
            <div class="mt10 flex">
                <div style="width: 155px;" class="flex alcenter">
                    <div style="width: 70px;">类型：</div>
                    <a-select v-model="orderData.type" style="width: 85px;">
                        <a-select-option :value="1">标准版</a-select-option>
                        <a-select-option :value="2">企业版</a-select-option>
                        <a-select-option :value="3">图片版</a-select-option>
                    </a-select>
                </div>
                <div style="width: 120px;" class="flex alcenter ml20">
                    <div style="width: 50px;">人数：</div>
                    <a-input v-model="orderData.num" style="width: 70px;" type="number" />
                </div>
                <div style="width: 175px;" class="ml20 flex alcenter">
                    <div style="width: 70px;">交付状态：</div>
                    <a-select v-model="orderData.deliver_status" style="width: 105px;">
                        <a-select-option :value="0">待交付</a-select-option>
                        <a-select-option :value="2">待修改</a-select-option>
                        <a-select-option :value="1">待反馈</a-select-option>
                        <a-select-option :value="4">没资料</a-select-option>
                        <a-select-option :value="5">部分制作</a-select-option>
                        <a-select-option :value="3">已完成</a-select-option>
                        <a-select-option :value="7">已退款</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="mt10 flex">
                <div style="width: 235px;" class="flex alcenter">
                    <div style="width: 70px;">客户群：</div>
                    <a-input v-model="orderData.crowd" style="width: 165px;" />
                </div>
                <div style="width: 175px;" class="ml20 flex alcenter">
                    <div style="width: 50px;">排序：</div>
                    <a-input v-model="orderData.sort" style="width: 125px;" type="number" />
                </div>
            </div>

            <div class="mt12 flex alcenter">
                <div style="width: 50px;">备注：</div>
                <a-textarea v-model="orderData.notes" :rows="4" />
            </div>
        </a-modal>
    </div>
</template>
<script>
import { apiCustomOrderList, apiCustomOrderSave } from '../../utils/js/api'
export default {
    props: {
        detail: {
            type: Object,
            default: function () {
                return new Object;
            }
        },
    },
    data() {
        return {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            show: false,
            title: '新增订单',
            orderData: {},
            data: {
                order_id: 0,
                custom_id: 0,

                consult: '',
                deliver_time:null,
                pay_type: 1,
                order_no: '',
                
                deal: 0,
                type:1,

                num: 1,

                crowd: '',
                sort: 0,
                notes: '',
            },
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            columns: [
                { title: '订单id', dataIndex: 'order_id', align: 'center' },
                { title: '支付方式', key: 'pay', align: 'center', scopedSlots: { customRender: 'pay' } },
                { title: '类型', key: 'type', align: 'center', scopedSlots: { customRender: 'type' }},
                { title: '金额', dataIndex: 'deal', align: 'center' },
                { title: '人数', dataIndex: 'num', align: 'center' },
                { title: '交付状态', key: 'deliver', align: 'center', scopedSlots: { customRender: 'deliver' } },
                { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },
            ],
            list: []
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            apiCustomOrderList({
                custom_id: this.detail.custom_id
            }).then(res => {
                this.list = res.list;
                this.pagination.total = res.total;
                console.log('res', res)
            })
        },
        add() {
            this.title = '新增订单';
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const day = now.getDate();
            this.data.consult = year + '-' + month + '-' + day
            this.data.custom_id = this.detail.custom_id;
            this.orderData = this.data;
            this.show = true;
        },
        edit(detail) {
            this.title = '修改订单';
            this.orderData = detail;
            this.show = true;
        },
        save() {
            apiCustomOrderSave(this.orderData).then(res => {
                this.show = false;
                this.getList();
            })
        },
        change(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getList();
        },
    }
}
</script>