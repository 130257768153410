<template>
    <div>
        <a-button @click="add" type="primary">开票</a-button>
        <a-table rowKey="invoice_id" :columns="columns" :pagination="pagination" @change="change" :data-source="list"
            :loading="loading" class="mt10">
            <!-- 发票类型 -->
            <template slot="type" slot-scope="record,index">
                <div>
                    <a-tag color="red" v-if="record.invoice_type == 1">专票</a-tag>
                    <a-tag color="green" v-if="record.invoice_type == 0">普票</a-tag>
                </div>
                <div class="mt5">{{ record.amount }}</div>
            </template>
            <!-- 开票信息 -->
            <template slot="info" slot-scope="record,index">
                <div>{{ record.name }}</div>
                <!-- <div class="mt5">{{ record.code }}</div> -->
            </template>
            <!-- 状态 -->
            <template slot="status" slot-scope="record,index">
                <a-tag color="red" v-if="record.status == 0">待开票</a-tag>
                <a-tag color="orange" v-if="record.status == 1">开票中</a-tag>
                <a-tag color="purple" v-if="record.status == 2">已开票</a-tag>
                <a-tag color="green" v-if="record.status == 3">已完成</a-tag>
            </template>
            <!-- 更多信息 -->
            <template slot="expandedRowRender" slot-scope="record,index">
                <div>税号：{{ record.code }}</div>
                <div>备注：</div>{{ record.notes }}
             </template>
            <!-- 操作 -->
            <template slot="action" slot-scope="record,index">
                <a-button type="primary" shape="circle" icon="edit" @click="edit(record)" />
            </template>
        </a-table>
        <a-modal width="400px" :title="title" :visible="show" @cancel="show = false" @ok="save">
            <a-form-model :model="invoiceData" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="类型">
                    <a-radio-group v-model="invoiceData.invoice_type" button-style="solid" style="width: 155px;">
                        <a-radio-button :value="0">普票</a-radio-button>
                        <a-radio-button :value="1">专票</a-radio-button>
                    </a-radio-group>
                </a-form-model-item>

                <a-form-model-item label="公司名称">
                    <a-input v-model="invoiceData.name" style="width: 200px;" />
                </a-form-model-item>
                <a-form-model-item label="开票号">
                    <a-input v-model="invoiceData.code" style="width: 200px;" />
                </a-form-model-item>
                <a-form-model-item label="金额">
                    <a-input v-model="invoiceData.amount" style="width: 200px;" type="number" />
                </a-form-model-item>
                <a-form-model-item label="备注">
                    <a-textarea v-model="invoiceData.notes" :rows="4" style="width: 230px;" />
                </a-form-model-item>
                <a-form-model-item label="排序">
                    <a-input v-model="invoiceData.sort" style="width: 200px;" type="number" />
                </a-form-model-item>
                <a-form-model-item label="状态">
                    <a-select v-model="invoiceData.status" style="width: 200px;">
                        <a-select-option :value="0">待开票</a-select-option>
                        <a-select-option :value="1">开票中</a-select-option>
                        <a-select-option :value="2">已开票</a-select-option>
                        <a-select-option :value="3">已完成</a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import { apiCustomInvoiceList, apiCustomInvoiceSave } from '../../utils/js/api'
export default {
    props: {
        detail: {
            type: Object,
            default: function () {
                return new Object;
            }
        },
    },
    data() {
        return {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            show: false,
            title: '新增订单',
            invoiceData: {},
            data: {
                invoice_id: 0,
                custom_id: 0,
                invoice_type: 0,
                name: '',
                code: '',
                amount: 0,
                status: 0,
                notes: '',
                sort: 0
            },
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            columns: [
                { title: '发票id', dataIndex: 'invoice_id', align: 'center' },
                { title: '开票信息', key: 'info', align: 'center', scopedSlots: { customRender: 'info' } },
                { title: '类型/金额', key: 'type', align: 'center', scopedSlots: { customRender: 'type' } },
                // { title: '类型/金额', dataIndex: 'amount', align: 'center' },
                // { title: '备注', dataIndex: 'notes', align: 'center' },
                { title: '状态', key: 'status', align: 'center', scopedSlots: { customRender: 'status' } },
                { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },
            ],
            list: []
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            apiCustomInvoiceList({
                custom_id: this.detail.custom_id
            }).then(res => {
                this.list = res.list;
                this.pagination.total = res.total;
                console.log('res', res)
            })
        },
        add() {
            this.title = '开票';
            this.data.custom_id = this.detail.custom_id;
            this.invoiceData = this.data;
            this.show = true;
        },
        edit(detail) {
            this.title = '修改开票信息';
            this.invoiceData = detail;
            this.show = true;
        },
        save() {
            apiCustomInvoiceSave(this.invoiceData).then(res => {
                this.show = false;
                this.getList();
            })
        },
        change(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getList();
        },
    }
}
</script>